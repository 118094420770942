// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---atacama-packages-packages-themes-theme-default-src-pages-404-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/404.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-404-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/contact.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-contact-js" */),
  "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js": () => import("./../../../../../../atacama-packages/packages/themes/theme-default/src/pages/thanks.js" /* webpackChunkName: "component---atacama-packages-packages-themes-theme-default-src-pages-thanks-js" */),
  "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/datenschutz-social.mdx" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-datenschutz-social-mdx" */),
  "component---atacama-packages-packages-web-core-src-pages-info-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/pages/---info.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-pages-info-js" */),
  "component---atacama-packages-packages-web-core-src-templates-page-legal-js": () => import("./../../../../../../atacama-packages/packages/web-core/src/templates/page/legal.js" /* webpackChunkName: "component---atacama-packages-packages-web-core-src-templates-page-legal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leistungen-mdx": () => import("./../../../src/pages/leistungen.mdx" /* webpackChunkName: "component---src-pages-leistungen-mdx" */),
  "component---src-pages-praxis-mdx": () => import("./../../../src/pages/praxis.mdx" /* webpackChunkName: "component---src-pages-praxis-mdx" */),
  "component---src-pages-videos-mdx": () => import("./../../../src/pages/videos.mdx" /* webpackChunkName: "component---src-pages-videos-mdx" */)
}

